import React, {useEffect, useState} from 'react'
import '../styles/formPage.css'
import axios from 'axios'
import { url } from '../URL'
import formatDate from '../Components/formatDate'
import UnAuthorized from '../Components/UnAuthorized'
import Alert from '../Components/Alert'
import { checkConnection } from '../utils/checkConnection'

function Debts() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [unAuthorized, setUnauthorized] = useState(false)
  const [isAlertActive,setIsAlertActive] = useState(false)
  const [currentData,setCurrentData] = useState({})
  async function payRemaining(id, remaining, paid){
    console.log({data: {remaining, paid, id}})
    checkConnection()
    await axios.put(`${url}/sessions/pay-debts`, {
      data: {remaining, paid, id}
    }, {withCredentials:true})
    .then(res => {
      setIsAlertActive(false)
      window.location.reload()
    })
    .catch(err => {
      if(err){
        if(err.response.status === 401){
          setUnauthorized(true)
        }
        if(err.response.status === 400){
          window.location = '/login'
        }
      }
    })
  }

  async function loadData(){
    await axios.get(`${url}/sessions/debts`, {withCredentials:true})
              .then(res => {
                setData(res.data.data)
                setIsLoading(false)
              })
              .catch(err => {
                console.log(err)
                if(err.response.status === 401){
                  setUnauthorized(true)
                }
                setIsLoading(false)
              })
  }
  
  useEffect(() => {
    if(isLoading){
      loadData()
    }
  }, [isLoading])
  return unAuthorized ? <UnAuthorized /> : (
    <main className='page-container debts form-page'>
      <Alert classText={isAlertActive ? "alert active" : "alert"} dont={() => setIsAlertActive(false)} do={async () => {
        // console.log(currentData)
        await payRemaining(currentData._id, currentData.remaining, currentData.paid)
      }} />
      <div className='container'>
        <section className='head-section' style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
          <div>
            <h1 className='title'>جميع الديون</h1>
          </div>
          <div>
            <h2 style={{color:"var(--primary)"}}>العدد: {data.length}</h2>
          </div>
        </section>
        {/* <section className='filter patients'>
          <div className='field-container'>
            <label>البحث</label>
            <div className='search-bar-grid'>
              <div className='icon'>
                <BsSearch />
              </div>
              <div className='search-bar'>
                <input type='text' placeholder='بحث...' className='field' value={search} onChange={e => {setSearch(e.target.value)}} />
              </div>
            </div>
          </div>
            <div>
              <label>الجنس</label>
                <div className='dropdown-container'>
                  <div className='gender' onClick={() => {openDropdown("gender")}}>{gender || 'الجميع'}</div>
                  <div className={dropdowns.gender ? 'dropdown active' : 'dropdown'}>
                    <ul>
                      <li onClick={() => {
                        openDropdown('')
                        setGender("")
                      }}>الجميع</li>
                      <li onClick={() => {
                        openDropdown('')
                        setGender("ذكر")
                      }}>ذكر</li>
                      <li onClick={() => {
                        openDropdown('')
                        setGender("أنثى")
                      }}>انثى</li>
                    </ul>
                  </div>
                </div>
            </div>
            <div>
              <label>الخدمة</label>
              <div className='dropdown-container'>
                <div className='gender' onClick={() => {openDropdown("service")}}>{service || 'الجميع'}</div>
                    <div className={dropdowns.service ? 'dropdown active' : 'dropdown'}>
                    <ul>
                        <li onClick={() => {
                        openDropdown('')
                        setService("")
                      }}>الجميع</li>
                        {
                          Object.keys(servicesAndCategories).map((data, index) => {
                            return <li key={index} onClick={() => {
                              openDropdown('')
                              setService(data)
                            }}
                            >{data}</li>
                          })
                        }
                    </ul>
                  </div>
              </div>
            </div>
        </section> */}
        <section className='patients'>
          <div className='table-container'>
            {
              data.length > 0 ? (
                <table>
            <thead>
              <tr>
                <th>
                  أسم المراجع
                </th>
                <th>
                  تاريخ الجلسة
                </th>
                <th>
                  الفئة
                </th>
                <th>
                  الخدمة
                </th>
                <th>
                  عدد الجلسات
                </th>
                <th>
                  المبلغ الأجمالي
                </th>
                <th>
                  المبلغ المدفوع
                </th>
                <th>
                  الخصم
                </th>
                <th>
                  المبلغ المتبقي
                </th>
                <th>
                  دفع المبلغ
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td>{d.patient}</td>
                      <td>{formatDate(d.start)}</td>
                      <td>{d.category}</td>
                      <td>{d.service}</td>
                      <td>{d.number}</td>
                      <td>{d.cost * d.number}</td>
                      <td>{d.paid}</td>
                      <td>{d.discount}</td>
                      <td>{d.cost * d.number - d.discount - d.paid}</td>
                      <td>
                        <button onClick={(e) => {
                          e.preventDefault()
                          setIsAlertActive(true)
                          setCurrentData(d)
                        }}>دفع</button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
              ) : (
                <div className='not-found'>
                  <div className='image'>
                    <img src={require('../Assets/Images/not found.png')} alt='not-found' loading='lazy' />
                  </div>
                  <div>
                    <h1><span>عذراً, </span>يبدو أن لا يوجد هناك نتائج</h1>
                  </div>
                </div>
              )
            }
          </div>
          
        </section>
      </div>  
    </main>        
  )
}

export default Debts