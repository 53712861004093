import React, {useState} from 'react'
import '../styles/Sidebar.css'
import * as BsIcons from 'react-icons/bs'
import { Link } from 'react-router-dom'
import LogoutAlert from './LogoutAlertWarning'
function Sidebar() {

    const [collapsedSections, setCollapsedSections] = useState({
        patients:true,
        doctors:true,
        expenses:true,
        products:true,
        sessions:true,
        services:true,
        system:true,
    })
    const [logoutAlert ,setLogoutAlert] = useState(false)
  return (
    <aside className='sidebar'>
        <div className={logoutAlert ? 'shadow active' : 'shadow'} onClick={() => {setLogoutAlert(false)}}></div>
        <LogoutAlert isActive={logoutAlert} cancel={() => setLogoutAlert(false)} />
       <div className='content'>
        <div className='logo'>
            <div className='img'>
                <img src={require('../Assets/Images/logo.png')} alt='logo' loading='lazy' />
            </div>
            <div className='logout' onClick={() => {
                setLogoutAlert(true)
            }}>
                <BsIcons.BsBoxArrowRight />
            </div>
        </div>
       <div className='container'>
            <section className={collapsedSections.patients ? '' : 'isOpen'}>
                <h3 onClick={() => {setCollapsedSections({...collapsedSections, patients: !collapsedSections.patients})}}>
                 
                        <div>
                            المراجعين
                        </div>
                        <div className='openIcon'>
                            <div></div>
                            <div></div>
                        </div>
                    
                </h3>
                <ul>
                    <li>
                        <Link to={'/patients/add'}>
                            <div className='icon'>
                                <BsIcons.BsPersonAdd />
                            </div>
                            <div className='text'>
                                أضافة مراجعين
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/patients/'}>
                        <div className='icon'>
                            <BsIcons.BsPerson />
                        </div>
                        <div className='text'>
                            جميع المراجعين
                        </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/materials/sell/choose-patient'}>
                            <div className='icon'>
                                <BsIcons.BsBagCheck />
                            </div>
                            <div className='text'>
                                بيع منتج
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/patients/visits'}>
                            <div className='icon'>
                                <BsIcons.BsPersonDash />
                            </div>
                            <div className='text'>
                                الزيارات
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/patients/birthdays'}>
                            <div className='icon'>
                                <BsIcons.BsCake />
                            </div>
                            <div className='text'>
                                أعياد الميلاد
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/patients/debt'}>
                            <div className='icon'>
                                <BsIcons.BsCoin />
                            </div>
                            <div className='text'>
                                الديون
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/patients/most-paid'}>
                            <div className='icon'>
                                <BsIcons.BsStars />
                            </div>
                            <div className='text'>
                                الأكثر دفعا
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link to={'/patients/contact'}>
                            <div className='icon'>
                                <BsIcons.BsPhone />
                            </div>
                            <div className='text'>
                                تواصل
                            </div>
                        </Link>
                    </li> */}
                    <li>
                        <Link to={'/patients/meta-data'}>
                            <div className='icon'>
                                <BsIcons.BsMeta />
                            </div>
                            <div className='text'>
                                سحب بيانات Meta
                            </div>
                        </Link>
                    </li>
                </ul>
            </section>
            <section className={collapsedSections.doctors ? '' : 'isOpen'}>
                <h3 onClick={() => {setCollapsedSections({...collapsedSections, doctors: !collapsedSections.doctors})}}>
                    <div>
                        الموظفين
                    </div>
                    <div className='openIcon'>
                        <div></div>
                        <div></div>
                    </div>
                </h3>
                <ul>
                    <li>
                        <Link to={'/employees/add'}>
                            <div className='icon'>
                                <BsIcons.BsPersonAdd />
                            </div>
                            <div className='text'>
                                أضافة موظف
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/employees/'}>
                            <div className='icon'>
                                <BsIcons.BsPerson />
                            </div>
                            <div className='text'>
                                جميع الموظفين
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/employees/attendance'}>
                            <div className='icon'>
                                <BsIcons.BsCalendar4Week />
                            </div>
                            <div className='text'>
                                جدول حضور الموظفين
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/employees/receipts'}>
                            <div className='icon'>
                                <BsIcons.BsReceipt />
                            </div>
                            <div className='text'>
                                وصولات الموظفين
                            </div>
                        </Link>
                    </li>
                </ul>
            </section>
            <section className={collapsedSections.expenses ? '' : 'isOpen'}>
                <h3 onClick={() => {setCollapsedSections({...collapsedSections, expenses: !collapsedSections.expenses})}}>
                    <div>
                        المصروفات
                    </div>
                    <div className='openIcon'>
                        <div></div>
                        <div></div>
                    </div>
                </h3>
                <ul>
                    <li>
                        <Link to={'/expenses/revenues'}>
                            <div className='icon'>
                                <BsIcons.BsWallet />
                            </div>
                            <div className='text'>
                                الأيرادات
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/expenses/salaries'}>
                            <div className='icon'>
                                <BsIcons.BsWallet />
                            </div>
                            <div className='text'>
                                رواتب الموظفين
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/expenses/products'}>
                            <div className='icon'>
                            <BsIcons.BsCashCoin />
                            </div>
                            <div className='text'>
                                مصروفات المواد
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/expenses/withdrawl'}>
                            <div className='icon'>
                            <BsIcons.BsCashCoin />
                            </div>
                            <div className='text'>
                                سحب من الصندوق
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/expenses/withdrawl-report'}>
                            <div className='icon'>
                            <BsIcons.BsReceipt />
                            </div>
                            <div className='text'>
                                سجل السحب
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/expenses/financial-fund'}>
                            <div className='icon'>
                            <BsIcons.BsCashCoin />
                            </div>
                            <div className='text'>
                                الصندوق المالي
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/expenses/add-financial-fund'}>
                            <div className='icon'>
                            <BsIcons.BsCashCoin />
                            </div>
                            <div className='text'>
                                أضافة الصندوق المالي
                            </div>
                        </Link>
                    </li>
                </ul>
            </section>
            <section className={collapsedSections.products ? '' : 'isOpen'}>
                <h3 onClick={() => {setCollapsedSections({...collapsedSections, products: !collapsedSections.products})}}>
                    <div>
                        المواد
                    </div>
                    <div className='openIcon'>
                        <div></div>
                        <div></div>
                    </div>
                </h3>
                <ul>
                    <li>
                        <Link to={'/materials/cosmetics'}>
                            <div className='icon'>
                                <BsIcons.BsShopWindow />
                            </div>
                            <div className='text'>
                                مواد الكوزمتكس
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/materials/storage'}>
                            <div className='icon'>
                                <BsIcons.BsShopWindow />
                            </div>
                            <div className='text'>
                                مواد المخزن
                            </div>
                        </Link>
                    </li>
                    
                    <li>
                        <Link to={'/preparers/'}>
                            <div className='icon'>
                                <BsIcons.BsPeople />
                            </div>
                            <div className='text'>
                                المجهزين
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/materials/purchases'}>
                            <div className='icon'>
                                <BsIcons.BsBag />
                            </div>
                            <div className='text'>
                                المشتريات
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/materials/debts'}>
                            <div className='icon'>
                                <BsIcons.BsBag />
                            </div>
                            <div className='text'>
                                الديون
                            </div>
                        </Link>
                    </li>
                </ul>
            </section>
            <section className={collapsedSections.services ? '' : 'isOpen'}>
                <h3 onClick={() => {setCollapsedSections({...collapsedSections, services: !collapsedSections.services})}}>
                    <div>
                        الخدمات والفئات
                    </div>
                    <div className='openIcon'>
                        <div></div>
                        <div></div>
                    </div>
                </h3>
                <ul>
                    <li>
                        <Link to={'/services/add-category'}>
                            <div className='icon'>
                                <BsIcons.BsCloudPlus />
                            </div>
                            <div className='text'>
                                أضافة فئة
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/services/add-service'}>
                            <div className='icon'>
                                <BsIcons.BsCloudPlus />
                            </div>
                            <div className='text'>
                                أضافة خدمة
                            </div>
                        </Link>
                    </li>
                </ul>
            </section>
            <section className={collapsedSections.sessions ? '' : 'isOpen'}>
                <h3 onClick={() => {setCollapsedSections({...collapsedSections, sessions: !collapsedSections.sessions})}}>
                    <div>
                        الجلسات
                    </div>
                    <div className='openIcon'>
                        <div></div>
                        <div></div>
                    </div>
                </h3>
                <ul>
                    <li>
                        <Link to={'/patients/choose-patient'}>
                            <div className='icon'>
                                <BsIcons.BsPlusCircleDotted />
                            </div>
                            <div className='text'>
                                أضافة جلسة
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/sessions/'}>
                            <div className='icon'>
                                <BsIcons.BsHourglassBottom />
                            </div>
                            <div className='text'>
                                جميع الجلسات
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/sessions/pending'}>
                            <div className='icon'>
                                <BsIcons.BsHourglassBottom />
                            </div>
                            <div className='text'>
                                جلسات قيد الأنتطار
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/sessions/completed'}>
                            <div className='icon'>
                                <BsIcons.BsHourglassBottom />
                            </div>
                            <div className='text'>
                                جلسات مكتملة
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/receipts'}>
                            <div className='icon'>
                                <BsIcons.BsReceipt />
                            </div>
                            <div className='text'>
                                الوصولات
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/receipts/set-images'}>
                            <div className='icon'>
                                <BsIcons.BsImage />
                            </div>
                            <div className='text'>
                                انشاء صور الوصولات
                            </div>
                        </Link>
                    </li>
                </ul>
            </section>
            <section className={collapsedSections.system ? '' : 'isOpen'}>
                <h3 onClick={() => {setCollapsedSections({...collapsedSections, system: !collapsedSections.system})}}>
                    <div>
                        النظام
                    </div>
                    <div className='openIcon'>
                        <div></div>
                        <div></div>
                    </div>
                </h3>
                <ul>
                    <li>
                        <Link to={'/system/logs'}>
                            <div className='icon'>
                            <BsIcons.BsBoxArrowRight />
                            </div>
                            <div className='text'>
                                عمليات تسجيل الدخول
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/system/devices'}>
                            <div className='icon'>
                            <BsIcons.BsPcDisplay />
                            </div>
                            <div className='text'>
                                الأجهزة
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/system/create-attendance'}>
                            <div className='icon'>
                            <BsIcons.BsPlusCircleDotted />
                            </div>
                            <div className='text'>
                                إنشاء حسابات حضور
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/system/cancelled-sessions'}>
                            <div className='icon'>
                            <BsIcons.BsXCircle />
                            </div>
                            <div className='text'>
                                الجلسات الملغية
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/system/info'}>
                            <div className='icon'>
                            <BsIcons.BsInfoCircle />
                            </div>
                            <div className='text'>
                                معلومات
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link to={'/system/settings'}>
                            <div className='icon'>
                            <BsIcons.BsGear />
                            </div>
                            <div className='text'>
                                الأعدادات
                            </div>
                        </Link>
                    </li> */}
                </ul>
            </section>
                
        </div>
       </div>
    </aside>
  )
}

export default Sidebar