function calculateTotal(cart) {
    if (!cart.products || !Array.isArray(cart.products)) {
      throw new Error("Invalid cart structure");
    }
  
    const total = cart.products.reduce((acc, product) => {
      return acc + product.soldQuantity * product.sellingPrice;
    }, 0);
    return total;
  }
  export default calculateTotal