import React, { useEffect, useState } from 'react';
import '../styles/ReceiptPaper.css'; // Assuming you extract the CSS to an external file
import { BsFacebook, BsGeo, BsInstagram, BsTelephone, BsWhatsapp } from 'react-icons/bs';
import formatTime from './formatTime';
import formatNumberWithDots from './formatNumberDots';
// import calculateTotal from './CalculateCartTotal';


function calculateTotal(cart) {
    if (!cart.products) {
      throw new Error("Invalid cart structure");
    }
    const total = cart?.products.reduce((acc, product) => {
      return acc + product.soldQuantity * product.sellingPrice;
    }, 0);
    return total;
  }
const ProductReceipt = ({ sessionData }) => {
    const [total, setTotal] = useState(0)
    useEffect(() => {
        if(sessionData.products){
            console.log('SESSION DATATATAT', sessionData)
            // console.log(calculateTotal(sessionData))
            setTotal(calculateTotal(sessionData))
        }
    }, [sessionData])
    return (
        <div className="receipt-paper" id='receiptsPaper' dir='ltr'>
            <div className="reciepe-receipt-paper">
                <div className="logo">
                    <img src={require('../Assets/Images/logo.png')} loading="lazy" alt="logo" />
                </div>
                <div className="dates">
                    <p>{`${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`}</p>
                    <p className="hour">{formatTime()}</p>
                </div>
            </div>
            <div className="content">
                <ul>
                    <li>
                        <div className="label">:المراجع</div>
                        <div className="text">{sessionData.patientName}</div>
                    </li>
                    <li>
                        <div className="label">:المجموع</div>
                        <div className="text">{formatNumberWithDots(total)} د.ع</div>
                    </li>
                    <li>
                        <div className="label">:الخصم</div>
                        <div className="text">{formatNumberWithDots(sessionData.discount) || 0} د.ع</div>
                    </li>
                    <li>
                        <div className="label">:المدفوع</div>
                        <div className="text">{formatNumberWithDots(total - sessionData.discount)} د.ع</div>
                    </li>
                </ul>
                <table style={{border:"none"}} dir='rtl'>
                    <thead>
                        <tr>
                            <th>المنتج</th>
                            <th>العدد</th>
                            <th>السعر</th>
                            <th>المجموع</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sessionData.products ? sessionData.products.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {data.name}
                                        </td>
                                        <td>
                                            {data.soldQuantity}
                                        </td>
                                        <td>
                                            {formatNumberWithDots(data.sellingPrice)} د.ع
                                        </td>
                                        <td>
                                            {formatNumberWithDots(data.sellingPrice * data.soldQuantity)} د.ع
                                        </td>
                                    </tr>
                                )
                            }) : null
                        }
                    </tbody>
                </table>
            </div>
            <div className="contacts">
                <div className="row">
                    <div className="socials">
                        <div className="icons">
                            <div>
                                {<BsInstagram />}
                            </div>
                            <div>
                                {<BsFacebook />}
                            </div>
                            <div>
                                {<BsWhatsapp />}
                            </div>
                        </div>
                        <div>
                            <p>@Pelinclinic</p>
                        </div>
                    </div>
                    <div className="location">
                        <div className="icons">
                            <div>
                                {<BsGeo />}
                            </div>
                        </div>
                        <div>
                            <p>البصرة, العباسية, شارع سيد حامد</p>
                        </div>
                    </div>
                </div>
                <div className="phone">
                    <div>
                        <BsTelephone />
                    </div>
                    <div>0780 088 0051 - 0772 910 7070</div>
                </div>
            </div>
        </div>
    );
};

export default ProductReceipt;
