import React, {useState, useEffect} from 'react'
import '../styles/formPage.css'
import axios from 'axios'
import { url } from '../URL'
import Loading from './Loading'
import {Link, useSearchParams } from 'react-router-dom'
import formatNumberWithDots from './formatNumberDots'
import '../styles/Checkout.css'
import RecieptAlert from './RecieptAlert'
import html2canvas from 'html2canvas'
import getCurrentTime12HourFormat from './getCurrentTime12HourFormat'
import ProductReceipt from './ProductsReceipt'
import calculateTotal from './CalculateCartTotal'
import convertNumberToWords from './NumToText'

function Cart() {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [quantities, setQuantities] = useState({})
  const [params] = useSearchParams()
  const [total, setTotal] = useState(0)
  const [receiptUrl, _setReceiptUrl] = useState("")
  const [isReceiptActive, setIsReceiptActive] = useState(false)
  const [areSessionBeingAdded, setAreSessingBeingAdded] = useState(false)
  const [imageURL, setImageURL] = useState('')
  const [isCreatingReceipt, setIsCreatingReceipt] = useState(false)
  const generateRec = async (sessionData) => {
    const element = document.getElementById("receiptsPaper"); // Replace with your div's ID
    
    // const relation = sessionData.map(d => {return d._id}).join("_")
    const canvas = await html2canvas(element);
    const dataUrl = canvas.toDataURL("image/png"); 
     try {
          await axios.post(`${url}/cart/generate-receipt`, {data:{...sessionData, image:dataUrl, start:Date.now()}}, {
                withCredentials:true,
                // headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(res => {
                if(res.status === 201){
                  setIsCreatingReceipt(false)
                  setImageURL(res.data.imagePath)
                  alert("تم حفظ الوصل بنجاح")
                }
              })
              .catch(err => {
                alert('حدث خطا')
                console.log(err)
              })
            ;
        } catch (error) {
            console.error('Error generating receipt:', error);
        }
  };

  async function loadCart(id){

    await axios.get(`${url}/cart/${id}`, {withCredentials:true})
                .then(res => {
                  setTotal(calculateTotal(res.data.data))
                  setData(res.data.data)
                  res.data.data.products.forEach(d => {
                    setQuantities(q => {
                      return {...q, [d._id]: d.soldQuantity}
                    })
                  })
                  setIsLoading(false)
                })
                .catch(err => {
                  console.log(err)
                  setIsLoading(false)
                })
  }
  const cartId = params.get('cart')
  useEffect(() => {
    if(isLoading){
      loadCart(cartId)
      
    }
  })

  async function checkout(id){
    await axios.put(`${url}/cart/${id}/checkout`, {data}, {withCredentials:true})
                .then(res => {
                  console.log(res)
                })
                .catch(err => {
                  console.log(err)
                })
  }
  return (
    <main className='page-container patients-page form-page sell-product-page'>
      {data ? <RecieptAlert data={{...data, image:`/public/cartReceipts/${imageURL}`}} active={isReceiptActive} /> :  null}
      <div className={isReceiptActive ? "shadow active" : "shadow"} onClick={() => {
        setIsReceiptActive(false)
        window.location = '/materials/sell/choose-patient/'
        }}></div>
      <div className='container'>
        <section className='head-section' style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
          <div>
            <h2 className='title'>الخطوة الثالثة: اتمام الشراء</h2>
            <h1 className='title'>جميع المنتجات التي تم اختياريها</h1>
          </div>
        </section>
        <section className='patients'>
          {
            data.products && data.products.length > 0 ? isLoading ? <Loading />  : (
              <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>
                  أسم المنتج
                </th>
                <th>
                  سعر القطعة
                </th>
                <th>
                  العدد
                </th>
                <th>
                  مجموع سعر المنتج
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data.products.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td>{d.name}</td>
                      <td>{formatNumberWithDots(d.sellingPrice)} د.ع</td>
                      <td>
                        {quantities[d._id]}
                      </td>
                      <td >{formatNumberWithDots(d.sellingPrice * quantities[d._id])} د.ع</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <div className='total-price'>
            <div>
              <h4>المجموع الكلي</h4>
            </div>
            <div>
              <h4>{formatNumberWithDots(total) || 0} د.ع</h4>
            </div>
          </div>
          <div className='field-container' style={{margin:'1rem 0'}}>
            <label htmlFor='discount'>الخصم الكلي <span>{convertNumberToWords(data.discount)}</span></label>
            <input type='number' className='field' value={data.discount} min={0} onChange={(e) => {setData(d => {
              return {...d, discount:parseInt(e.target.value)}
            })}} name='discount' />
          </div>
          <Link to={`/materials/sell/add-products?cart=${params.get('cart')}`}>
              <button style={{background:"var(--accent)", width:"fit-content", color:"var(--bg)", marginTop:".5rem"}}>تغيير المنتجات</button>
          </Link>
          <button onClick={e => {
            e.preventDefault()
            const cartId = params.get('cart')
            setIsReceiptActive(true)
            generateRec(data)
            checkout(cartId)
          }} style={{background:"var(--primary)", width:"fit-content", color:"var(--bg)", marginTop:".5rem"}}>أتمام الشراء</button>
          </div>
            ) : (
              <div className='not-found' style={{display: "flex"}}>
            <div className='image'>
              <img src={require('../Assets/Images/not found.png')} alt='not-found' loading='lazy' />
            </div>
            <div>
              <h1><span>عذراً, </span>يبدو أنك لم تقم بأختيار منتجات</h1>
              <Link to={`/materials/sell/add-products?cart=${cartId}`}>
                <button style={{color:"var(--bg)", background:"var(--primary)", marginTop:'1rem'}}>
                  أختار منتج
                </button>
              </Link>
            </div>
          </div>
            )
          }
          
        </section>
      </div>  
    </main>        
  )
}

export default Cart