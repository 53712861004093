import React, { useState, useEffect } from 'react';
import { BsDash, BsPlus, BsSearch } from 'react-icons/bs';
import '../styles/formPage.css';
import axios from 'axios';
import { url } from '../URL';
import Loading from './Loading';
import UnAuthorized from '../Components/UnAuthorized';
import { useSearchParams } from 'react-router-dom';
import '../styles/sellProducts.css';
import { PuffLoader } from 'react-spinners';
import NotFound from './NotFound';
import formatNumberWithDots from './formatNumberDots';

function ChooseProduct() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [unAuthorized, setUnAuthorized] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [products, setProducts] = useState([]);
  const [params] = useSearchParams();
  const [cart, setCart] = useState({});
  const [msg, setMsg] = useState("")
  const filteredData = data.filter(d => d.name.includes(search));

  useEffect(() => {
    async function loadData() {
      await axios
        .get(`${url}/patients/cosmetics/`, {
          withCredentials: true,
        })
        .then(res => {
          const filtered = res.data.data.filter(d => d.quantity > 0)
          setData(filtered);
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
          if (err.response.status === 401) {
            setUnAuthorized(true);
          }
        });
    }
    if (isLoading) {
      loadData();
    }
  }, [isLoading, data]);

  async function loadCartData(id) {
    await axios
      .get(`${url}/cart/${id}`, { withCredentials: true })
      .then(res => {
        setCart(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    const cartId = params.get('cart');
    loadCartData(cartId);
  });

  function update(d, payload) {
    setQuantities(q => {
      const newQuantity = (q[d._id] || 0) + payload;
      return {
        ...q,
        [d._id]: newQuantity < 0 ? 0 : newQuantity > d.quantity ? d.quantity :  newQuantity,
      };
    });

    setProducts(p => {
      const existingProductIndex = p.findIndex(product => product._id === d._id);

      if (existingProductIndex !== -1) {
        const newSoldQuantity = p[existingProductIndex].soldQuantity + payload;

        if (newSoldQuantity > 0) {
          const updatedProducts = [...p];
          updatedProducts[existingProductIndex] = {
            ...updatedProducts[existingProductIndex],
            soldQuantity: newSoldQuantity,
          };
          return updatedProducts;
        } else {
          return p.filter((_, index) => index !== existingProductIndex);
        }
      } else if (payload > 0) {
        return [...p, { ...d, soldQuantity: payload }];
      }

      return p;
    });
  }

  function update2(d, payload) {
    if (payload < 0) return;
    if(payload > d.quantity){
      setQuantities(q => ({
        ...q,
        [d._id]: d.quantity,
      }));
    }else {
      setQuantities(q => ({
        ...q,
        [d._id]: payload,
      }));
    }

 

    setProducts(p => {
      const existingProductIndex = p.findIndex(product => product._id === d._id);

      if (existingProductIndex !== -1) {
        if (payload > 0) {
          const updatedProducts = [...p];
          updatedProducts[existingProductIndex] = {
            ...updatedProducts[existingProductIndex],
            soldQuantity: payload,
          };
          return updatedProducts;
        } else {
          return p.filter((_, index) => index !== existingProductIndex);
        }
      } else if (payload > 0) {
        return [...p, { ...d, soldQuantity: payload }];
      }

      return p;
    });
  }

  const [areAdding, setAreAdding] = useState(false)
  async function addProducts(data){
    const cartId = params.get('cart')
    setAreAdding(true)
    await axios.put(`${url}/cart/${cartId}/add-products`, {data}, {withCredentials:true}) 
    .then(res => {
              if(res.status === 200){
                window.location = `/materials/sell/checkout?cart=${cartId}`
              }
              setAreAdding(false)
            }).catch(err => {
              console.log(err.response.data)
              setMsg(err.response.data.message)
              setAreAdding(false)
              })
  }

  return unAuthorized ? (
    <UnAuthorized />
  ) :  !cart.patientName ? <main className="page-container patients-page form-page sell-product-page"><NotFound /></main> :(
    <main className="page-container patients-page form-page sell-product-page">
      <div className={areAdding ? 'loading active' : 'loading'}>
        <PuffLoader color='var(--primary)' />
      </div>
      <div className="container">
        <section className="head-section" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <h2 className="title">الخطوة الثانية: أختار المنتجات</h2>
            <h1 className="title">جميع المنتجات</h1>
            <p>قم باختيار منتجات لوضعها في سلة الزبون {cart.patientName}</p>
          </div>
          <div>
            <h2 style={{ color: 'var(--primary)' }}>العدد: {filteredData.length}</h2>
          </div>
        </section>
        <section className="filter patients" style={{ gridTemplateColumns: '.8fr .2fr' }}>
          <div className="field-container">
            <label>البحث</label>
            <div className="search-bar-grid">
              <div className="icon">
                <BsSearch />
              </div>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="بحث..."
                  className="field"
                  value={search}
                  onChange={e => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="patients">
          {filteredData.length > 0 ? (
            isLoading ? (
              <Loading />
            ) : (
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>أسم المنتج</th>
                      <th>الكمية المتاحة</th>
                      <th>سعر القطعة</th>
                      <th>العدد</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((d, i) => {
                      return (
                        <tr key={i}>
                          <td>{d.name}</td>
                          <td>{d.quantity}</td>
                          <td>{formatNumberWithDots(d.sellingPrice)} د.ع</td>
                          <td className="quantity-container">
                            <div
                              onClick={() => {
                                if(d > 0){
                                  update2(d, d.quantity)
                                }else {
                                  update(d, 1);
                                }
                              }}>
                              <BsPlus />
                            </div>
                            <input
                              className="field"
                              type="number"
                              value={quantities[d._id]}
                              onChange={e => {
                                const val = parseInt(e.target.value);
                                if (!isNaN(val)) {
                                  update2(d, val);
                                }
                                if(val > d.quantity){
                                  update2(d, d.quantity);
                                }
                              }}
                              name="quantity"
                              min={0}
                              max={d.quantity}
                            />
                            <div
                              onClick={() => {
                                if(d.quantity < 0){
                                  update2(d, 0)
                                }else {
                                  update(d, -1);
                                }
                              }}>
                              <BsDash />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <button onClick={(e) => {
                  e.preventDefault()
                  addProducts(products)
                }} style={{background:"var(--primary) !important",padding:"1rem 2rem", marginTop:".5rem", color:"var(--bg)", width:"fit-content"}}>
                  أتمام, الذهاب الى السلة
                </button>
                <p style={{textAlign:"center", color:"var(--primary)", marginTop:".5rem"}}>{msg}</p>
              </div>
            )
          ) : (
            <NotFound />
          )}
        </section>
      </div>
    </main>
  );
}

export default ChooseProduct;
