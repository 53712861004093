import React from 'react'
import { PuffLoader } from 'react-spinners'
function Loading() {
  return (
    <div style={{
      display:"flex",
      alignContent:"center",
      justifyContent:"center",
      alignItems:"center",
      width:"100dvw",
      height:"100dvh"
    }}>
      <PuffLoader color='#228a83' />
    </div>
  )
}

export default Loading